import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import featureImage from "../../static/images/hero-2.png";
import thumbnailEvent from "../../static/images/feature-commands.png"
import thumbnailBoard from "../../static/images/feature-dashboard.png"
import thumbnailNews from "../../static/images/feature-channel.png"
import newMoonImage from "../../static/images/icons8-new-moon-64.png";
import waxingMoonImage from "../../static/images/icons8-waxing-crescent-64.png";
import halfMoonImage from "../../static/images/icons8-first-quarter-64.png";
import fullMoonImage from "../../static/images/icons8-full-moon-64.png";
import firstImage from "../../static/images/icons8-1st-64.png";
import secondImage from "../../static/images/icons8-circled-2-64.png";
import thirdImage from "../../static/images/icons8-circled-3-64.png";
import fourthImage from "../../static/images/icons8-circled-4-64.png";
import fifthImage from "../../static/images/icons8-circled-5-64.png";
import sixthImage from "../../static/images/icons8-circled-6-64.png";


const IndexPage = () => (
    <Layout>
        <SEO title="The Slack App For Twitter and Instagram That Does More"/>

        <div className={"page-header home"}>
            <h1>Let Your Community Interact on Twitter and Instagram Without Leaving Slack</h1>
            <p>Xapnik is the Twitter & Instagram Slack app that does more: follow another member, see their posts, see the group's latest activity and more, without ever leaving Slack.</p><p>Launching February 2021.</p>
            <div className={"button-solid"}>
                <a href={"https://slackform.xapnik.com/pre2012"} className={"center"}>Notify Me</a>
            </div>

            <img alt={"Dashboard"} src={featureImage}/>
        </div>

        <div className={"container"}>
            <div className={"features"}>
                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <div className={"thumbnail"}>
                                <img alt={"Event"} src={thumbnailEvent}/>
                            </div>
                        </div>

                        <div className={"col-6 first"}>
                            <div className={"feature__content"}>
                                <h2>Slack commands to engage members on Twitter and Instagram.</h2>
                                <p>See anyone's recent posts. Follow other members on Twitter & Instagram. Get quick stats on the community's overall social activity. All without leaving Slack.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"feature__content"}>
                                <h2>Group Dashboard to See and Do Even More</h2>
                                <p>Your community has its own dashboard displaying everyone's most recent posts. Like, reply, re-post, all from the dashboard. Find community member posts to highlight back in Slack.</p>
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"thumbnail"}>
                                <img alt={"Board"} src={thumbnailBoard}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <div className={"thumbnail"}>
                                <img alt={"News"} src={thumbnailNews}/>
                            </div>
                        </div>

                        <div className={"col-6 first"}>
                            <div className={"feature__content"}>
                                <h2>Dedicated Slack Channel For Quick Stats</h2>
                                <p>At-a-glance stats of your group's latest activity on Twitter and Instagram. One click opens the Xapnik dashboard where you can see everyone's posts and quickly Like, Reply, Repost. Grab a post link to easily share it back in Slack.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"featured-ol"}>
                    <h2>How It Works</h2>
                    <p><img alt={"First"} src={firstImage}/>Xapnik invites your members to connect their Twitter and Instagram accounts.</p>
                    <p><img alt={"Second"} src={secondImage}/>Members who connect their accounts can use Xapnik to follow each other on Twitter and Instagram directly within Slack.</p>
                    <p><img alt={"Third"} src={thirdImage}/>Members can use Xapnik's commands to see and engage with each other's newest Twitter or Instagram posts right from Slack.</p>
                    <p><img alt={"Fourth"} src={fourthImage}/>The Xapnik Bot channel updates group Twitter and Instagram stats to encourage members to engage.</p>
                    <p><img alt={"Fifth"} src={fifthImage}/>Your community gets its own customized Xapnik dashboard where members can follow and keep up with each other on Twitter and Instagram.</p>
                    <p><img alt={"Sixth"} src={sixthImage}/>You can use the Xapnik dashboard to quickly find member posts to highlight in Slack.</p>    

                    <div className={"button-solid"}>
                        <a href={"https://slackform.xapnik.com/pre2012"} className={"center"}>Notify Me</a>
                    </div>
                </div>

    
            </div>
        </div>

        <div className={"pricing"}>
            <div className={"targets"}>
                <div className={"title"}>
                    <h2>Pricing</h2>
                </div>

                <div className={"row"}>
                    <div className={"col-3"}>
                        <div className={"item"}>
                            <img alt={"Free"} src={newMoonImage}/>
                            <h3>Free</h3>
                            <p>
                                <ul>
                                    <li>1 team</li>
                                    <li>Up to 15 members</li>
                                </ul>
                            </p>
                        </div>
                    </div>

                    <div className={"col-3"}>
                        <div className={"item"}>
                            <img alt={"Premium"} src={waxingMoonImage}/>
                            <h3>$7.99 per month</h3>
                            <p>
                                <ul>
                                    <li>3 teams</li>
                                    <li>100 members per team</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                    <div className={"col-3"}>
                        <div className={"item"}>
                            <img alt={"Pro"} src={halfMoonImage}/>
                            <h3>$49.99 per month</h3>
                            <p>
                                <ul>
                                    <li>7 teams</li>
                                    <li>250 members per team</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                    <div className={"col-3"}>
                        <div className={"item"}>
                            <img alt={"Org"} src={fullMoonImage}/>
                            <h3>$99.99 per month</h3>
                            <p>
                                <ul>
                                    <li>15 teams</li>
                                    <li>250+ members per team</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className={"call-to-action"}>
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-9 call-to-action__content"}>
                        <h2>Get notified when we launch in February 2021.</h2>
                    </div>

                    <div className={"col-3 first button-white"}>
                        <a href="https://slackform.xapnik.com/pre2012" target={"_blank"}>Notify Me</a>
                    </div>
                </div>    
            </div>
        </div>
    </Layout>
)

export default IndexPage
